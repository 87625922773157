/* src/App.css */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.card {
  width: 100%;
  text-align: center;
}

h1 {
  color: #3b5998;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
}

button {
  padding: 10px;
  margin: 10px 0;
  background-color: #3b5998;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #2e4377;
}

.error {
  color: #ff4d4f;
  font-size: 14px;
}

.success {
  color: #52c41a;
  font-size: 14px;
}

.success a {
  color: #3b5998;
  text-decoration: none;
}

.success a:hover {
  text-decoration: underline;
}
